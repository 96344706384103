import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";

import MaterialReactTable from "material-react-table";

// import Button from "@mui/material/Button";

const Business = () => {
  const [business, setBusiness] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [error, setError] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  let navigate = useNavigate();

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem("userData"));

    const getBusiness = async () => {
      if (!business.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/businesses?pagenumber=${
            pagination.pageIndex
          }&pagesize=${pagination.pageSize}&keyword=${globalFilter ?? ""}`,
          {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }

        setBusiness(data.business);
        setRowCount(data.totalDocuments);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    getBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, globalFilter]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "phone",
        header: "Phone",
      },
      {
        accessorKey: "behavior",
        header: "Behavior",
        Cell: ({ cell }) =>
          cell.getValue() === 1
            ? "Normal"
            : cell.getValue() === 2
            ? "Banned"
            : cell.getValue() === 3
            ? "Out of service"
            : cell.getValue() === 4
            ? "Reported"
            : "No Status",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) =>
          cell.getValue() === 0
            ? "Pending"
            : cell.getValue() === 1
            ? "Created"
            : cell.getValue() === 3
            ? "Claimed"
            : "No Status",
      },
      {
        accessorKey: "verified",
        header: "Verified",
        Cell: ({ cell }) => (cell.getValue() ? "Verified" : "No Verified"),
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ cell }) => (
          <Moment format="YYYY/MM/DD">{cell.getValue()}</Moment>
        ),
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={business}
      getRowId={(row) => row._id}
      rowCount={rowCount}
      manualPagination
      manualFiltering
      enableColumnActions={false}
      enableColumnFilters={false}
      onPaginationChange={setPagination}
      onGlobalFilterChange={setGlobalFilter}
      muiTablePaperProps={{
        sx: {
          display: { lg: "grid", md: "grid", sm: "grid", xs: "grid" },
        },
      }}
      // renderTopToolbarCustomActions={({ table }) => {
      //   return (
      //     <div style={{ display: "flex", gap: "0.5rem", paddingTop: "3px" }}>
      //       <Button
      //         onClick={() => {
      //           navigate("create");
      //         }}
      //         variant="contained"
      //       >
      //         CREATE BUSINESS
      //       </Button>
      //     </div>
      //   );
      // }}
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => {
          setRowSelection((prev) => ({
            [row.id]: !prev[row.id],
          }));
          navigate(`${row.id}`);
        },
        selected: rowSelection[row.id],
        sx: {
          cursor: "pointer",
        },
      })}
      muiToolbarAlertBannerProps={
        !!error
          ? {
              color: "error",
              children: error.message,
            }
          : undefined
      }
      state={{
        isLoading: isLoading,
        pagination: pagination,
        globalFilter: globalFilter,
        showProgressBars: isRefetching,
        showAlertBanner: !!error,
      }}
    />
  );
};

export default Business;
