import React from "react";
import Moment from "react-moment";
import "moment-timezone";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const LatestBusinessTable = (props) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        gridColumn: "3/span 2",
      }}
    >
      <Typography>Recently added business</Typography>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.latestBusinesses.map((business) => (
              <TableRow
                key={business._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {business.name}
                </TableCell>
                <TableCell align="left">{business.address}</TableCell>
                <TableCell align="left">
                  <Moment format="YYYY/MM/DD">{business.createdAt}</Moment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default LatestBusinessTable;
