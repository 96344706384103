import React from "react";
import Moment from "react-moment";
import "moment-timezone";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const UserBehaviorCard = (props) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Active</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              {props.user.authenticationMethod ? "Enabled" : "Disabled"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Status</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              {props.user.status === 1
                ? "Normal"
                : props.user.status === 2
                ? "Removed"
                : "Other"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Behavior</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              {props.user.behavior === 1
                ? "Normal"
                : props.user.behavior === 2
                ? "Banned"
                : "Other"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Roles</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              {props.user.roles === "user"
                ? "User"
                : props.user.roles === "admin"
                ? "Admin"
                : props.user.roles === "operator"
                ? "Operator"
                : "Other"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Type</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              {props.user.type === "public" ? "Public" : "Private"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Auth Method</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              {props.user.authenticationMethod === 1
                ? "Email"
                : props.user.authenticationMethod === 2
                ? "Facebook"
                : props.user.authenticationMethod === 3
                ? "Google"
                : props.user.authenticationMethod === 4
                ? "Apple"
                : "Other"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Created At</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={7}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              <Moment format="YYYY/MM/DD">{props.user.createdAt}</Moment>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserBehaviorCard;
