import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const UserRemoveModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const closeHandler = () => {
    props.setOpen(false);
  };

  const removeUserHandler = async (e) => {
    e.preventDefault();

    props.setOpen(false);

    const storeData = JSON.parse(localStorage.getItem("userData"));

    props.setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${props.userId}/profile/remove`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${storeData.token}`,
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      props.setMessage(data.message);
    } catch (err) {
      props.setError(err.message);
    }

    props.setIsLoading(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={closeHandler}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Remove a user</DialogTitle>
      <DialogContent>
        <DialogContentText>
          By removing a user, you remove the user from the application except
          from the database. It is considered a delete account for the user. Do
          you agree to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant="contained" onClick={removeUserHandler}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserRemoveModal;
