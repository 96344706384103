import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";

import MaterialReactTable from "material-react-table";

const BusinessVerificationList = () => {
  const [verification, setVerification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [error, setError] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  let navigate = useNavigate();

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem("userData"));

    const getBusinessVerification = async () => {
      if (!verification.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_URL
          }/api/verifications/business?pagenumber=${
            pagination.pageIndex
          }&pagesize=${pagination.pageSize}&keyword=${globalFilter ?? ""}`,
          {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }

        setVerification(data.business);
        setRowCount(data.totalDocuments);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    getBusinessVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, globalFilter]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "business.name",
        header: "Business",
      },
      {
        accessorKey: "user.username",
        header: "Firstname",
      },
      {
        accessorKey: "user.profile.firstname",
        header: "Firstname",
      },
      {
        accessorKey: "user.profile.lastname",
        header: "Lastname",
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ cell }) => (
          <Moment format="YYYY/MM/DD">{cell.getValue()}</Moment>
        ),
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={verification}
      getRowId={(row) => row._id}
      rowCount={rowCount}
      manualPagination
      manualFiltering
      enableColumnActions={false}
      enableColumnFilters={false}
      onPaginationChange={setPagination}
      onGlobalFilterChange={setGlobalFilter}
      muiTablePaperProps={{
        sx: {
          display: { lg: "grid", md: "grid", sm: "grid", xs: "grid" },
        },
      }}
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => {
          setRowSelection((prev) => ({
            [row.id]: !prev[row.id],
          }));
          navigate(`../verification/${row.id}/business`, { replace: true });
        },
        selected: rowSelection[row.id],
        sx: {
          cursor: "pointer",
        },
      })}
      muiToolbarAlertBannerProps={
        !!error
          ? {
              color: "error",
              children: error.message,
            }
          : undefined
      }
      state={{
        isLoading: isLoading,
        pagination: pagination,
        globalFilter: globalFilter,
        showProgressBars: isRefetching,
        showAlertBanner: !!error,
      }}
    />
  );
};

export default BusinessVerificationList;
