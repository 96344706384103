import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const UserDeleteModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  let navigate = useNavigate();

  const closeHandler = () => {
    props.setOpen(false);
  };

  const deleteUserHandler = async (e) => {
    e.preventDefault();

    props.setOpen(false);

    const storeData = JSON.parse(localStorage.getItem("userData"));

    props.setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${props.userId}/profile/delete`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${storeData.token}`,
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      props.setMessage(data.message);
    } catch (err) {
      props.setError(err.message);
    }

    props.setIsLoading(false);

    navigate("/dashboard/users", { replace: true });
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={closeHandler}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Delete a user permanently
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          When deleting a user, you eliminate it from the database along with
          all the information linked to that user, making it impossible to
          recover any data. Do you agree to continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant="contained" onClick={deleteUserHandler}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDeleteModal;
