import React, { Fragment, useState } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import Loading from "../../shared/components/UIElements/Loading";
import ErrorAlert from "../../shared/components/UIElements/ErrorAlert";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const UserCreate = () => {
  const values = {
    username: "",
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    phone: "",
    roles: "user",
    type: "public",
  };

  const [userInput, setUserInput] = useState(values);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const changeHandler = async (e) => {
    setUserInput((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };

  const createUserHandler = async (e) => {
    e.preventDefault();

    const storeData = JSON.parse(localStorage.getItem("userData"));

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${storeData.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userInput),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
  };

  const clearErrorHandler = () => {
    setError(null);
  };

  return (
    <Fragment>
      {error && <ErrorAlert error={error} onClear={clearErrorHandler} />}
      <Loading loading={isLoading} />
      <Box
        component="form"
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiTextField-root": { marginBottom: 1 },
          m: "auto",
          width: { lg: "40%", md: "50%", sm: "flex", xs: "flex" },
        }}
      >
        <Paper elevation={3} sx={{ p: 3 }}>
          <TextField
            fullWidth
            id="username"
            label="Username"
            name="username"
            variant="standard"
            value={userInput.username}
            onChange={changeHandler}
          />
          <TextField
            fullWidth
            id="email"
            label="Email"
            name="email"
            variant="standard"
            value={userInput.email}
            onChange={changeHandler}
          />
          <FormControl fullWidth variant="standard" sx={{ marginBottom: 1 }}>
            <InputLabel htmlFor="create-password">Password</InputLabel>
            <Input
              id="create-password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={userInput.password}
              onChange={changeHandler}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPasswordHandler}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <TextField
            fullWidth
            id="firstname"
            label="First Name"
            name="firstname"
            variant="standard"
            value={userInput.firstname}
            onChange={changeHandler}
          />
          <TextField
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            variant="standard"
            value={userInput.lastname}
            onChange={changeHandler}
          />
          <TextField
            fullWidth
            id="mainAddress"
            label="Address"
            name="mainAddress"
            variant="standard"
            value={userInput.mainAddress}
            onChange={changeHandler}
          />
          <TextField
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
            variant="standard"
            value={userInput.phone}
            onChange={changeHandler}
          />
          <FormControl fullWidth variant="standard" sx={{ marginBottom: 1 }}>
            <InputLabel id="select-rol-label">Rol</InputLabel>
            <Select
              labelId="select-rol-label"
              id="select-rol"
              label="Roles"
              name="roles"
              displayEmpty
              value={userInput.roles}
              onChange={changeHandler}
            >
              <MenuItem value="user">Locals Atlas' User</MenuItem>
              <MenuItem value="operator">Operator</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="standard" sx={{ marginBottom: 1 }}>
            <InputLabel id="select-type-label">Type</InputLabel>
            <Select
              labelId="select-type-label"
              id="select-type"
              label="Type"
              name="type"
              displayEmpty
              value={userInput.type}
              onChange={changeHandler}
            >
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="private">Private</MenuItem>
            </Select>
          </FormControl>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={createUserHandler}>
                CREATE
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default UserCreate;
