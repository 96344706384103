import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
// import Button f rom "@mui/material/Button";

const ClaimResumeActionCard = () => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid container sx={{ paddingBottom: 3 }}>
        <Grid item>
          <Typography>Resume</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              The user requesting verification is the creator and owner of the
              business
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              The business has a claim by the user who wants verification
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              The business has 0 reports
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 5 }} />
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button fullWidth variant="contained" onClick={() => {}}>
            VERIFY
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button fullWidth variant="contained">
            ASK MORE INFO
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button fullWidth variant="contained">
            DENY
          </Button>
        </Grid>
      </Grid> */}
    </Paper>
  );
};

export default ClaimResumeActionCard;
