import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const BusinessInfoCard = (props) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid container sx={{ paddingBottom: 3 }}>
        <Grid item>
          <Typography>Business Information</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              Name
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {props.verification.business.name}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              Description
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {props.verification.business.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              Address
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {props.verification.business.address}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              Phone
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {props.verification.business.phone}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
    </Paper>
  );
};

export default BusinessInfoCard;
