import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const UserInfoCard = (props) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Username</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">{props.user.username}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Full Name</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">{`${props.user.profile.firstname} ${props.user.profile.lastname}`}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Email</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">{props.user.email}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Phone</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">{props.user.profile.phone}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Main Address</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">{props.user.mainAddress}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Second Address</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              {props.user.optionalAddress01}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">Third Address</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">
              {props.user.optionalAddress02}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserInfoCard;
