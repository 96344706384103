import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import Loading from "../../shared/components/UIElements/Loading";
import ErrorAlert from "../../shared/components/UIElements/ErrorAlert";
import SuccessAlert from "../../shared/components/UIElements/SuccessAlert";

import UserDeleteModal from "../components/UserDeleteModal";
import UserRemoveModal from "../components/UserRemoveModal";

const UserEdit = () => {
  let location = useLocation();

  let user = location.state.user;

  const values = {
    username: user.username,
    firstname: user.profile.firstname,
    lastname: user.profile.lastname,
    image: user.profile.image,
    phone: user.profile.phone,
    roles: user.roles,
    status: user.status,
    behavior: user.behavior,
    isActive: user.isActive,
    type: user.type,
    observation: "",
  };

  const [userInput, setUserInput] = useState(values);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const openDeleteModalHandler = () => {
    setOpenDeleteModal(true);
  };

  const openRemoveModalHandler = () => {
    setOpenRemoveModal(true);
  };

  const changeHandler = (e) => {
    setUserInput((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const updateUserHandler = async (e) => {
    e.preventDefault();

    const storeData = JSON.parse(localStorage.getItem("userData"));

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${user._id}/profile`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${storeData.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userInput),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      setMessage("User update was successful");
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
  };

  const clearErrorHandler = () => {
    setError(null);
  };

  const clearMessageHandler = () => {
    setMessage(null);
  };

  return (
    <Fragment>
      <Loading loading={isLoading} />
      <Box
        component="form"
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiTextField-root": { marginBottom: 1 },
          m: "auto",
          width: { lg: "40%", md: "50%", sm: "flex", xs: "flex" },
        }}
      >
        <Paper elevation={3} sx={{ p: 3 }}>
          <TextField
            fullWidth
            id="username"
            label="Username"
            name="username"
            variant="standard"
            value={userInput.username}
            onChange={changeHandler}
          />
          <TextField
            fullWidth
            id="firstname"
            label="First Name"
            name="firstname"
            variant="standard"
            value={userInput.firstname}
            onChange={changeHandler}
          />
          <TextField
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            variant="standard"
            value={userInput.lastname}
            onChange={changeHandler}
          />
          <TextField
            fullWidth
            id="image"
            label="Image"
            name="image"
            variant="standard"
            value={userInput.image}
            onChange={changeHandler}
          />
          <TextField
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
            variant="standard"
            value={userInput.phone}
            onChange={changeHandler}
          />
          <FormControl fullWidth variant="standard" sx={{ marginBottom: 1 }}>
            <InputLabel id="select-rol-label">Rol</InputLabel>
            <Select
              labelId="select-rol-label"
              id="select-rol"
              label="Roles"
              variant="standard"
              displayEmpty
              name="roles"
              value={userInput.roles}
              onChange={changeHandler}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="operator">Operator</MenuItem>
              <MenuItem value="user">Locals Atlas' User</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="standard" sx={{ marginBottom: 1 }}>
            <InputLabel id="select-is-active-label">Is Active</InputLabel>
            <Select
              labelId="select-is-active-label"
              id="select-is-status"
              label="isActive"
              displayEmpty
              name="isActive"
              value={userInput.isActive}
              onChange={changeHandler}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="standard" sx={{ marginBottom: 1 }}>
            <InputLabel id="select-behavior-label">Behavior</InputLabel>
            <Select
              labelId="select-behavior-label"
              id="select-behavior"
              name="behavior"
              label="Behavior"
              displayEmpty
              value={userInput.behavior}
              onChange={changeHandler}
            >
              <MenuItem value={1}>Normal</MenuItem>
              <MenuItem value={2}>Banned</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="standard" sx={{ marginBottom: 1 }}>
            <InputLabel id="select-type-label">Type</InputLabel>
            <Select
              labelId="select-type-label"
              id="select-type"
              label="Type"
              name="type"
              displayEmpty
              value={userInput.type}
              onChange={changeHandler}
            >
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="private">Private</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            id="observation"
            label="Observation"
            name="observation"
            variant="standard"
            required
            value={userInput.observation}
            onChange={changeHandler}
          />
          {error && <ErrorAlert error={error} onClear={clearErrorHandler} />}
          {message && (
            <SuccessAlert message={message} onClear={clearMessageHandler} />
          )}
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={4}>
              <Button fullWidth variant="contained" onClick={updateUserHandler}>
                UPDATE
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                onClick={openRemoveModalHandler}
              >
                REMOVE
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                onClick={openDeleteModalHandler}
              >
                DELETE
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <UserRemoveModal
        userId={user._id}
        open={openRemoveModal}
        setOpen={setOpenRemoveModal}
        setIsLoading={setIsLoading}
        setMessage={setMessage}
        setError={setError}
      />
      <UserDeleteModal
        userId={user._id}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        setIsLoading={setIsLoading}
        setMessage={setMessage}
        setError={setError}
      />
    </Fragment>
  );
};

export default UserEdit;
