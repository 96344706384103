import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Loading from "../../shared/components/UIElements/Loading";
import ErrorModal from "../../shared/components/UIElements/ErrorAlert";

import UserResumeActionCard from "../components/UserResumeActionCard";
import UserInfoCard from "../components/UserInfoCard";
import UserBehaviorCard from "../components/UserBehaviorCard";

const User = () => {
  const [user, setUser] = useState();
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [error, setError] = useState();

  const { id } = useParams();

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem("userData"));

    const getUserById = async () => {
      setIsLoadingUser(true);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/users/${id}/profile`,
          {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }
        setUser(data);
      } catch (err) {
        setError(err.message);
      }
      setIsLoadingUser(false);
    };

    getUserById();
  }, [id]);

  const clearErrorHandler = () => {
    setError(null);
  };

  return (
    <Fragment>
      {error && <ErrorModal error={error} onClear={clearErrorHandler} />}
      <Loading loading={isLoadingUser} />
      <Box
        sx={{
          display: { lg: "grid", md: "flex", sm: "flex", xs: "flex" },
          gridTemplateColumns: "max-content auto auto",
          gridAutoRows: "minmax(100px, auto)",
          gap: 2,
          textAlign: "center",
          flexDirection: "column",
          marginBottom: 2,
        }}
      >
        {!isLoadingUser && user && (
          <Fragment>
            <UserResumeActionCard user={user} />
            <UserInfoCard user={user} />
            <UserBehaviorCard user={user} />
          </Fragment>
        )}
      </Box>
      <Box
        sx={{
          display: { lg: "grid", md: "flex", sm: "flex", xs: "flex" },
          gridTemplateColumns: "max-content max-content auto",
          gridAutoRows: "minmax(100px, auto)",
          gridTemplateAreas: `"card1 card2 table" "card3 card4 table"`,
          gap: 2,
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        {!isLoadingUser && user && (
          <Fragment>
            <Paper elevation={3} sx={{ gridArea: "card1", p: 3 }}></Paper>
            <Paper elevation={3} sx={{ gridArea: "card2", p: 3 }}></Paper>
            <Paper elevation={3} sx={{ gridArea: "card3", p: 3 }}></Paper>
            <Paper elevation={3} sx={{ gridArea: "card4", p: 3 }}></Paper>
            <Paper elevation={3} sx={{ gridArea: "table", p: 3 }}></Paper>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default User;
