import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import Loading from "../../shared/components/UIElements/Loading";
import ErrorAlert from "../../shared/components/UIElements/ErrorAlert";

import VerificationBusinessInfoCard from "../components/business/VerificationInfoCard";
import VerificationUserInfoCard from "../components/business/VerificationUserInfoCard";
import BusinessInfoCard from "../components/business/BusinessInfoCard";
import CreatorOwnerInfoCard from "../components/business/CreatorOwnerInfoCard";
import ClaimInfoCard from "../components/business/ClaimInfoCard";
import VerificationResumeActionCard from "../components/business/VerificationResumeActionCard";
import SuccessAlert from "../../shared/components/UIElements/SuccessAlert";

const BusinessVerificationDetail = () => {
  const [verification, setVerification] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [message, setMessage] = useState();

  const { id } = useParams();

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem("userData"));

    const getBusinessVerification = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/verifications/${id}/business`,
          {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }

        setVerification(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    getBusinessVerification();
  }, [id, isLoading]);

  const clearErrorHandler = () => {
    setError(null);
  };

  const clearMessageHandler = () => {
    setMessage(null);
  };

  return (
    <Fragment>
      {error && <ErrorAlert error={error} onClear={clearErrorHandler} />}
      {message && (
        <SuccessAlert message={message} onClear={clearMessageHandler} />
      )}
      <Loading loading={isLoading} />
      <Box
        sx={{
          display: { lg: "grid", md: "flex", sm: "flex", xs: "flex" },
          gridTemplateColumns: "auto auto auto",
          gridAutoRows: "minmax(100px, auto)",
          gap: 2,
          textAlign: "center",
          flexDirection: "column",
          marginBottom: 2,
        }}
      >
        {!isLoading && verification && (
          <Fragment>
            <VerificationBusinessInfoCard verification={verification} />
            <VerificationUserInfoCard verification={verification} />
            <BusinessInfoCard verification={verification} />
            <CreatorOwnerInfoCard verification={verification} />
            <ClaimInfoCard verification={verification} />
            <VerificationResumeActionCard
              verificationId={id}
              verification={verification}
              setError={setError}
              setMessage={setMessage}
              setIsLoading={setIsLoading}
            />
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default BusinessVerificationDetail;
