import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import Loading from "../../shared/components/UIElements/Loading";
import ErrorAlert from "../../shared/components/UIElements/ErrorAlert";

import BusinessInfoCard from "../components/BusinessInfoCard";
import BusinessCreatorInfoCard from "../components/BusinessCreatorInfoCard";
import BusinessOwnerInfoCard from "../components/BusinessOwnerInfoCard";

const BusinessDetail = () => {
  const [business, setBusiness] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  const { id } = useParams();

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem("userData"));

    const getBusiness = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/businesses/${id}`,
          {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }

        setBusiness(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    getBusiness();
  }, [id, isLoading]);

  const clearErrorHandler = () => {
    setError(null);
  };

  return (
    <Fragment>
      {error && <ErrorAlert error={error} onClear={clearErrorHandler} />}
      <Loading loading={isLoading} />
      <Box
        sx={{
          display: { lg: "grid", md: "flex", sm: "flex", xs: "flex" },
          gridTemplateColumns: "auto auto auto",
          gridAutoRows: "minmax(100px, auto)",
          gap: 2,
          textAlign: "center",
          flexDirection: "column",
          marginBottom: 2,
        }}
      >
        {!isLoading && business && (
          <Fragment>
            <BusinessInfoCard business={business} />
            <BusinessCreatorInfoCard business={business} />
            <BusinessOwnerInfoCard business={business} />
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default BusinessDetail;
