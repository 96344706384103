import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import Loading from "../../shared/components/UIElements/Loading";
import ErrorModal from "../../shared/components/UIElements/ErrorAlert";

import ClaimInfoCard from "../components/ClaimInfoCard";
import ClaimUserInfoCard from "../components/ClaimUserInfoCard";
import ClaimBusinessInfoCard from "../components/ClaimBusinessInfoCard";
import ClaimCreatorInfoCard from "../components/ClaimCreatorInfoCard";
import ClaimOwnerInfoCard from "../components/ClaimOwnerInfoCard";
import ClaimResumeActionCard from "../components/ClaimResumeActionCard";

const Claim = () => {
  const [claim, setClaim] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { id } = useParams();

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem("userData"));

    const getClaim = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/claims/${id}`,
          {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }

        setClaim(data);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };

    getClaim();
  }, [id]);

  const clearErrorHandler = () => {
    setError(null);
  };

  return (
    <Fragment>
      {error && <ErrorModal error={error} onClear={clearErrorHandler} />}
      <Loading loading={isLoading} />
      <Box
        sx={{
          display: { lg: "grid", md: "flex", sm: "flex", xs: "flex" },
          gridTemplateColumns: "auto auto auto",
          gridAutoRows: "minmax(100px, auto)",
          gap: 2,
          textAlign: "center",
          flexDirection: "column",
          marginBottom: 2,
        }}
      >
        {!isLoading && claim && (
          <Fragment>
            <ClaimInfoCard claim={claim} />
            <ClaimUserInfoCard claim={claim} />
            <ClaimBusinessInfoCard claim={claim} />
            <ClaimCreatorInfoCard claim={claim} />
            <ClaimOwnerInfoCard claim={claim} />
            <ClaimResumeActionCard claim={claim} />
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default Claim;
