import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

import Paper from "@mui/material/Paper";

import "leaflet-canvas-marker";
import L from "leaflet";

import "leaflet/dist/leaflet.css";

function LeafletCanvasMarker(props) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    var ciLayer = L.canvasIconLayer({}).addTo(map);

    var icon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
      iconSize: [25, 25],
      iconAnchor: [10, 20],
    });

    let markers = [];

    for (let m = 0; m < props.markers.length; m++) {
      const element = props.markers[m];
      const marker = L.marker(
        [element.location.coordinates[1], element.location.coordinates[0]],
        { icon: icon }
      ).bindPopup(`Name: ${element.name}`);
      markers.push(marker);
    }
    ciLayer.addLayers(markers);
  }, [props.markers, map]);

  return null;
}

const BusinessMap = (props) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        gridColumn: "3/span 2",
      }}
    >
      <MapContainer
        center={[-0.18075789328990582, -78.46727373220156]}
        zoom={13}
        style={{ height: "500px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LeafletCanvasMarker markers={props.businessesLocation} />
      </MapContainer>
    </Paper>
  );
};

export default BusinessMap;
