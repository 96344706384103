import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

const VerificationResumeActionCard = (props) => {
  let isUserRequestingCreator;
  let isUserRequestingOwner;
  let existingClaimData;
  let isUserClaimsCreator;
  let isUserClaimsOwner;

  if (
    props.verification.user._id.toString() ===
    props.verification.business.userCreator._id.toString()
  ) {
    isUserRequestingCreator = "The user requesting verification is the creator";
  } else {
    isUserRequestingCreator =
      "The user requesting verification is NOT the creator";
  }

  if (
    props.verification.user._id.toString() ===
    props.verification.business.ownerUser._id.toString()
  ) {
    isUserRequestingOwner = "The user requesting verification is the owner";
  } else {
    isUserRequestingOwner = "The user requesting verification is NOT the owner";
  }

  if (props.verification.claim) {
    existingClaimData = "Claim data completed";
    if (
      props.verification.claim.user._id.toString() ===
      props.verification.business.userCreator._id.toString()
    ) {
      isUserClaimsCreator = "The user who claimed the business is the creator";
    } else {
      isUserClaimsCreator =
        "The user who claimed the business is NOT the creator";
    }

    if (
      props.verification.claim.user._id.toString() ===
      props.verification.business.ownerUser._id.toString()
    ) {
      isUserClaimsOwner = "The user who claimed the business is the owner";
    } else {
      isUserClaimsOwner = "The user who claimed the business is NOT the owner";
    }
  } else {
    existingClaimData = "Claim data incompleted";
    isUserClaimsCreator = "Insuficient data to analize";
    isUserClaimsOwner = "Insuficient data to analize";
  }

  const verifyHandler = async (e) => {
    e.preventDefault();

    const storeData = JSON.parse(localStorage.getItem("userData"));

    props.setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/verifications/${props.verificationId}/business`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${storeData.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            businessId: props.verification.business._id,
            verified: true,
            status: 2,
            observation: "Verification proccess was approved",
            results: [
              isUserRequestingCreator,
              isUserRequestingOwner,
              existingClaimData,
              isUserClaimsCreator,
              isUserClaimsOwner,
            ],
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      props.setMessage(data.message);
    } catch (err) {
      props.setError(err.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  const denyHandler = async (e) => {
    e.preventDefault();

    const storeData = JSON.parse(localStorage.getItem("userData"));

    props.setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/verifications/${props.verificationId}/business`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${storeData.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            businessId: props.verification.business._id,
            verified: false,
            status: 4,
            observation: "Verification proccess was denied",
            results: [
              isUserRequestingCreator,
              isUserRequestingOwner,
              existingClaimData,
              isUserClaimsCreator,
              isUserClaimsOwner,
            ],
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      props.setMessage(data.message);
    } catch (err) {
      props.setError(err.message);
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid container sx={{ paddingBottom: 3 }}>
        <Grid item>
          <Typography>Resume</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {isUserRequestingCreator}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {isUserRequestingOwner}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {existingClaimData}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {isUserClaimsCreator}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {isUserClaimsOwner}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 5 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={12}>
          <Button
            fullWidth
            variant="contained"
            disabled={props.verification.status === 2}
            onClick={verifyHandler}
          >
            VERIFY
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={12}>
          <Button fullWidth variant="contained">
            ASK MORE INFO
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={12}>
          <Button
            fullWidth
            variant="contained"
            disabled={props.verification.status === 4}
            onClick={denyHandler}
          >
            DENY
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VerificationResumeActionCard;
