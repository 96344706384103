import React, { Fragment, useMemo, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
// import ErrorIcon from "@mui/icons-material/Error";

import Home from "../../../home/pages/Home";
import Users from "../../../users/pages/Users";
import User from "../../../users/pages/User";
import UserEdit from "../../../users/pages/UserEdit";
import UserCreate from "../../../users/pages/UserCreate";
import Business from "../../../business/pages/Business";
import BusinessDetail from "../../../business/pages/BusinessDetail";
import BusinessEdit from "../../../business/pages/BusinessEdit";
import BusinessCreate from "../../../business/pages/BusinessCreate";
import Claims from "../../../claims/pages/Claims";
import Claim from "../../../claims/pages/Claim";
import BusinessVerificationList from "../../../verifications/pages/BusinessVerificationList";
import BusinessVerificationDetail from "../../../verifications/pages/BusinessVerificationDetail";
// import Errors from "../../../errors/pages/Errors";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = ({ open, setOpen }) => {
  const [selectedLink, setSelectedLink] = useState("");

  const navigate = useNavigate();

  const menu = useMemo(
    () => [
      {
        title: "Home",
        icon: <HomeIcon />,
        link: "home",
        component: <Home link={"home"} setSelectedLink={setSelectedLink} />,
      },
      {
        title: "Users",
        icon: <GroupIcon />,
        link: "users",
        component: <Users link={"users"} setSelectedLink={setSelectedLink} />,
      },
      {
        title: "Business",
        icon: <BusinessIcon />,
        link: "business",
        component: (
          <Business link={"business"} setSelectedLink={setSelectedLink} />
        ),
      },
      {
        title: "Claims",
        icon: <FactCheckIcon />,
        link: "claims",
        component: <Claims link={"claims"} setSelectedLink={setSelectedLink} />,
      },
      {
        title: "Verifications",
        icon: <WorkspacePremiumIcon />,
        link: "verification/business",
        component: (
          <BusinessVerificationList
            link={"verification/business"}
            setSelectedLink={setSelectedLink}
          />
        ),
      },
      // {
      //   title: "Errors",
      //   icon: <ErrorIcon />,
      //   link: "errors",
      //   component: <Errors link={"errors"} setSelectedLink={setSelectedLink} />,
      // },
    ],
    []
  );

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menu.map((item) => {
            return (
              <ListItem
                key={item.title}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={selectedLink === item.link}
                  onClick={() => navigate(item.link)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Routes>
          {menu.map((item) => (
            <Route key={item.title} path={item.link} element={item.component} />
          ))}
          <Route path="/users/:id" element={<User />} />
          <Route path="/users/:id/edit" element={<UserEdit />} />
          <Route path="/users/create" element={<UserCreate />} />
          <Route path="/business/:id" element={<BusinessDetail />} />
          <Route path="/business/:id/edit" element={<BusinessEdit />} />
          <Route path="/business/create" element={<BusinessCreate />} />
          <Route path="/claims/:id" element={<Claim />} />
          <Route
            path="/verification/:id/business"
            element={<BusinessVerificationDetail />}
          />
        </Routes>
      </Box>
    </Fragment>
  );
};

export default Sidebar;
