import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

const UserResumeActionCard = (props) => {
  const navigate = useNavigate();

  const goToEditUserHandler = () => {
    navigate("edit", { state: { user: props.user } });
  };

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
            <Avatar
              alt={props.user.username}
              src={`https://localsatlasprofiles.s3.amazonaws.com/${props.user.profile.image}`}
              sx={{ width: 100, height: 100 }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Typography variant="button">{props.user.username}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Typography variant="subtitle2">{props.user.email}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button fullWidth variant="contained" onClick={goToEditUserHandler}>
            Edit
          </Button>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={9}>
                  <Button fullWidth variant="outlined">
                    Reset Password
                  </Button>
                </Grid> */}
      </Grid>
    </Paper>
  );
};

export default UserResumeActionCard;
