import React, { useState, useEffect, Fragment } from "react";

import Box from "@mui/material/Box";

import Loading from "../../shared/components/UIElements/Loading";
import ErrorAlert from "../../shared/components/UIElements/ErrorAlert";

import UserResumeCard from "../components/UserResumeCard";
import UsersMonthlyChart from "../components/UsersMonthlyChart";
import LatestBusinessTable from "../components/LatestBusinessTable";
import UsersMap from "../components/UsersMap";
import BusinessMap from "../components/BusinessMap";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [resume, setResume] = useState();
  const [monthlyUsers, setMonthlyUsers] = useState();
  const [latestBusinesses, setLatestBusinesses] = useState();
  const [usersLocation, setUsersLocation] = useState();
  const [businessesLocation, setBusinessesLocation] = useState();

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem("userData"));

    const getResume = async () => {
      setIsLoading(true);

      try {
        const [
          overallInfo,
          monthlyUser,
          latestBusiness,
          userLocation,
          businessLocation,
        ] = await Promise.all([
          fetch(`${process.env.REACT_APP_BASE_URL}/api/users/resume`, {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }),
          fetch(`${process.env.REACT_APP_BASE_URL}/api/users/monthly`, {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }),
          fetch(`${process.env.REACT_APP_BASE_URL}/api/businesses/latest`, {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }),
          fetch(`${process.env.REACT_APP_BASE_URL}/api/users/location`, {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }),
          fetch(`${process.env.REACT_APP_BASE_URL}/api/businesses/location`, {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }),
        ]);

        const data = await Promise.all([
          overallInfo.json(),
          monthlyUser.json(),
          latestBusiness.json(),
          userLocation.json(),
          businessLocation.json(),
        ]);

        if (
          !overallInfo.ok &&
          !monthlyUser.ok &&
          !latestBusiness.ok &&
          !userLocation.ok &&
          !businessLocation.ok
        ) {
          setError("Fetching data failed, please try again");
        }

        setResume(data[0]);
        setMonthlyUsers(data[1]);
        setLatestBusinesses(data[2]);
        setUsersLocation(data[3]);
        setBusinessesLocation(data[4]);
      } catch (err) {
        setError("Fetching data failed, please try again");
      }

      setIsLoading(false);
    };

    getResume();
  }, []);

  const clearErrorHandler = () => {
    setError(null);
  };

  return (
    <Fragment>
      {error && <ErrorAlert error={error} onClear={clearErrorHandler} />}
      <Loading loading={isLoading} />
      <Box
        sx={{
          display: { lg: "grid", md: "flex", sm: "flex", xs: "flex" },
          gridTemplateColumns: "repeat(4, 1fr)",
          gridAutoRows: "minmax(100px, auto)",
          gap: 4,
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        {!isLoading && resume && monthlyUsers && latestBusinesses && (
          <Fragment>
            <UserResumeCard resume={resume} />
            <UsersMonthlyChart monthlyUsers={monthlyUsers} />
            <LatestBusinessTable latestBusinesses={latestBusinesses} />
            <UsersMap usersLocation={usersLocation} />
            <BusinessMap businessesLocation={businessesLocation} />
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default Home;
