import React from "react";
import Moment from "react-moment";
import "moment-timezone";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const VerificationBusinessInfoCard = (props) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid container sx={{ paddingBottom: 3 }}>
        <Grid item>
          <Typography>Verification Information</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              ID Type
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {props.verification.idType}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              ID Number
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2">{props.verification.idNumber}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              Email
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {props.verification.email}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              Phone
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {props.verification.phone}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              Address
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              {props.verification.address}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={12} sm={6} md={2} lg={3}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              Created At
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={10} lg={9}>
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" align="left">
              <Moment format="YYYY/MM/DD">{props.verification.createdAt}</Moment>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
    </Paper>
  );
};

export default VerificationBusinessInfoCard;
