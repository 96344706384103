import React, { Fragment } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const ClaimInfoCard = (props) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      {props.verification.claim ? (
        <Fragment>
          <Grid container sx={{ paddingBottom: 3 }}>
            <Grid item>
              <Typography>Claim Information - User</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={2} lg={3}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  User
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={10} lg={9}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  {props.verification.claim.user.username}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          <Grid container>
            <Grid item xs={12} sm={6} md={2} lg={3}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  Email
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={10} lg={9}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  {props.verification.claim.user.email}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          <Grid container>
            <Grid item xs={12} sm={6} md={2} lg={3}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  Name
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={10} lg={9}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  {`${props.verification.claim.user.profile.firstname} ${props.verification.claim.user.profile.lastname}`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          <Grid container sx={{ paddingBottom: 3, paddingTop: 3 }}>
            <Grid item>
              <Typography>Claim Information - Business</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={2} lg={3}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  Name
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={10} lg={9}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  {props.verification.claim.business.name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          <Grid container>
            <Grid item xs={12} sm={6} md={2} lg={3}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  Description
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={10} lg={9}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  {props.verification.claim.business.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          <Grid container>
            <Grid item xs={12} sm={6} md={2} lg={3}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  Phone
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={10} lg={9}>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2" align="left">
                  {props.verification.claim.business.phone}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        </Fragment>
      ) : (
        <Fragment>
          <Grid container sx={{ paddingBottom: 3 }}>
            <Grid item>
              <Typography>Claim Information</Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ paddingBottom: 3 }}>
            <Grid item>
              <Typography>Info not found</Typography>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Paper>
  );
};

export default ClaimInfoCard;
