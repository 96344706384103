import React from "react";

import Paper from "@mui/material/Paper";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const UsersMonthlyChart = (props) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: true,
        text: "Monthly Users",
      },
    },
  };

  const labels = props.monthlyUsers[0].data.map((user) => user.x);

  const data = {
    labels,
    datasets: [
      {
        label: "Monthly Users",
        data: props.monthlyUsers[0].data.map((user) => user.y),
        borderColor: "rgb(25, 118, 210)",
        backgroundColor: "rgba(25, 118, 210, 0.5)",
      },
    ],
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        gridColumn: "1/span 2",
      }}
    >
      <Line data={data} options={options} />
    </Paper>
  );
};

export default UsersMonthlyChart;
