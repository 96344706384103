import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";

import MaterialReactTable from "material-react-table";

import Button from "@mui/material/Button";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [error, setError] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  let navigate = useNavigate();

  useEffect(() => {
    const storeData = JSON.parse(localStorage.getItem("userData"));

    const getUsers = async () => {
      if (!users.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/users?pagenumber=${
            pagination.pageIndex
          }&pagesize=${pagination.pageSize}&keyword=${globalFilter ?? ""}`,
          {
            headers: {
              Authorization: `Bearer ${storeData.token}`,
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }

        setUsers(data.users);
        setRowCount(data.totalDocuments);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, globalFilter]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "username",
        header: "Username",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "profile.firstname",
        header: "First Name",
      },
      {
        accessorKey: "profile.lastname",
        header: "Last Name",
      },
      {
        accessorKey: "profile.phone",
        header: "Phone",
      },
      {
        accessorKey: "authenticationMethod",
        header: "Auth Method",
        Cell: ({ cell }) =>
          cell.getValue() === 1
            ? "Email"
            : cell.getValue() === 2
            ? "Facebook"
            : cell.getValue() === 3
            ? "Google"
            : cell.getValue() === 4
            ? "Apple"
            : "Other",
      },
      {
        accessorKey: "behavior",
        header: "Behavior",
        Cell: ({ cell }) =>
          cell.getValue() === 1
            ? "Normal"
            : cell.getValue() === 2
            ? "Banned"
            : "No Status",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) =>
          cell.getValue() === 1
            ? "Normal"
            : cell.getValue() === 2
            ? "Removed"
            : "No Status",
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ cell }) => (
          <Moment format="YYYY/MM/DD">{cell.getValue()}</Moment>
        ),
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={users}
      getRowId={(row) => row._id}
      rowCount={rowCount}
      manualPagination
      manualFiltering
      enableColumnActions={false}
      enableColumnFilters={false}
      onPaginationChange={setPagination}
      onGlobalFilterChange={setGlobalFilter}
      renderTopToolbarCustomActions={({ table }) => {
        return (
          <div style={{ display: "flex", gap: "0.5rem", paddingTop: "3px" }}>
            <Button
              onClick={() => {
                navigate("create");
              }}
              variant="contained"
            >
              CREATE USER
            </Button>
          </div>
        );
      }}
      muiTablePaperProps={{
        sx: {
          display: { lg: "grid", md: "grid", sm: "grid", xs: "grid" },
        },
      }}
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => {
          setRowSelection((prev) => ({
            [row.id]: !prev[row.id],
          }));
          navigate(`${row.id}`);
        },
        selected: rowSelection[row.id],
        sx: {
          cursor: "pointer",
        },
      })}
      muiToolbarAlertBannerProps={
        error
          ? {
              color: "error",
              children: error,
            }
          : undefined
      }
      state={{
        isLoading: isLoading,
        pagination: pagination,
        globalFilter: globalFilter,
        showProgressBars: isRefetching,
        showAlertBanner: error,
      }}
    />
  );
};

export default Users;
